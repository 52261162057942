export default function Header() {
    return (
        <header>
            <h1 className="type_display type_display__large">
                <span>Mike</span> 
                <span>Harsh</span>
            </h1>
            <h2 className="type_display type_display__small">
                <span>
                    certified professional
                </span>
                &nbsp;
                <span>
                    in web accessibility
                </span>
            </h2>
            <h2 className="type_display type_display__small">lead UI engineer</h2>
        </header>
    );
}
